<template>
  <div>
    <registration v-if="userData" :userData="userData"/>
  </div>
</template>

<script>
import Registration from "../../components/sign/registration";
export default {
  name: "user-registration",
  components: {
    Registration
  },
  data() {
    return {
      userData: null
    }
  },
  methods: {
    init(resData) {
      if (resData.signDocuments && resData.signDocuments.length > 0){
        for (let i = 0; i < resData.signDocuments.length; i++){
          if (resData.signDocuments[i].espType  === 'USER_SIGN_REGISTRATION'){
            this.userData = {
              signRequest : resData,
              signedDocument: resData.signDocuments[i]
            }
          }
        }
      }
    }
  },
  created() {
    this.$http.get('sign/url-param-decode/' + this.$route.params.code).then(res => {
      this.init(res.data)
    }, err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>